/* You can add global styles to this file, and also import other style files */
@use "sass:map";
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "variables";
@import "color-palettes";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/font-awesome/css/font-awesome.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import "../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
// @import "../../node_modules/angular-notifier/styles.css";
@import "material-overrides";
@import 'fonts';


// This sets the app background color. Do not change
body {
	background-color: #f5f5f6;
	font-family: $font-family;
}

.form-error {
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
}

.form-group {
	margin-bottom: 0.5rem;
}


// MARK: Form Field Customization
.form-field--thin, .search-wrapper {
	align-items: center;
	
	.mat-mdc-form-field-infix {
	  padding-top: 8px !important;
	  padding-bottom: 8px !important;
	}
  
	.mat-mdc-input-element {
	  height: 21px;
	}
  
	.mat-mdc-text-field-wrapper .mat-mdc-select-arrow-wrapper {
	  align-items: center;
	}
  }
  
  .form-field--thinner {
	align-items: center;
  
	.mat-mdc-form-field-infix {
	  padding-top: 4px !important;
	  padding-bottom: 4px !important;
	}
  
	.mat-mdc-input-element {
	  height: 20px;
	}
  }
  
  .search-wrapper .search-field {
	font-family: $font-family;
	font-size: 14px !important;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
  
	.prefix-icon {
	  padding: 10px;
	  width: 16px;
	  height: 16px;
	}
  }

// MARK: Legacy Filter Dropdown
.legacy-filter-dropdown {
	.mat-mdc-text-field-wrapper.mdc-text-field--filled {
	  border: 1px solid #f5f5f6; // Same color as app background so UI doesn't shift when border is added on hover/focus
	
	  &:hover:not(.mdc-text-field--focused, .mdc-text-field--invalid) {
		border: 1px solid map.get($ink-palette, 500);
	  }
	  
	  &:not(.mdc-text-field--disabled) {
		--mdc-filled-text-field-container-color: #f5f5f6;
	  }
	}
  }


.modal-backdrop {
	z-index: 2040;
}

.modal {
	z-index: 2050;
}

.modal-dialog {
	max-width: 476px;
	margin: 0 auto;
	position: relative;
  top: 40% !important;
  transform: translateY(-50%) !important;
}

.modal-content {
	width: 476px;
	border-radius: 6px;
  border: solid 1px #c7ced9;
  background-color: #ffffff;
}

.fab-container {
	position: fixed;
	right: 30px;
	bottom: 30px;
	padding-bottom: 2em;
	z-index: auto;
	.mat-mdc-fab {
		z-index: 10;
	}
	.mat-mdc-mini-fab{
		opacity: 0;
		z-index: 9;
		-webkit-transform: translateY(-60px);
		-ms-transform: translateY(-60px);
		transform: translateY(-60px);
		-webkit-transition: all 1s ease-out;
		transition: all 1s ease-out;
	}
	.mat-mini-fab-show {
		opacity: 1;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
}

#sidebar {
	height: 140vh !important;
}

// MARK: Native Table
.mat-elevation-z8 {
	&.table-container {
	  border-radius: 6px;
	  box-shadow: 0 0 0 0;
	  border: $border-box;
	  background-color: $white;
	  overflow: auto;
	  max-height: 70vh;
	  padding: 0 1rem 1rem 1rem;
  
	  table {
		width: 100%;
		font-weight: 400;
		color: map.get($ink-palette, 900);
  
		thead tr {
		  min-height: 36px;
		  border-bottom: solid 1px map.get($ink-palette, 300);
  
		  th {
			font-size: 10px;
			font-family: $font-family;
			font-weight: $font-weight-bolder;
			color: map.get($primary-palette, 900);
			word-wrap: break-word !important;
			white-space: nowrap;
			overflow-wrap: break-word;
			word-wrap: break-word;
			word-break: break-word;
			-ms-hyphens: auto;
			-moz-hyphens: auto;
			-webkit-hyphens: auto;
			hyphens: auto;
			line-height: 1;
			padding: 0 0.625rem 0 1rem;
	
			.mat-sort-header-arrow {
			  margin: 0 10px 0;
			}
  
			th[aria-sort=descending] {
			  .mat-sort-header-arrow {
				margin: 5px 10px 0;
			  }
			}
  
			mat-sort-header-stem {
			  display: none !important;
			}
		  }  
		}
		
		tbody tr {
		  border-bottom-width: 0;
		  height: 36px;
		  
		  &:nth-child(even) {
			background-color: $table-even-color;
		  }
		  &:hover, &:focus {
			background-color: map.get($primary-palette, 50) !important;
			outline: map.get($primary-palette, 500) auto .5px;
		  }
		  &.selected {
			background-color: map.get($primary-palette, 50) !important;
		  }
		  td {
			font-size: 14px;
			font-family: $font-family;
			font-weight: $font-weight-bold;
			color: $table-font-color;
			padding: 0 0.625rem 0 1rem;
			border-bottom-width: 0;
		  }
		}
	  }
  
	  // Scrollbar customization
	  &::-webkit-scrollbar {
		width: 0.375rem;
		height: 0.375rem;
	  }
  
	  &::-webkit-scrollbar-thumb {
		background-color: #bac1c7;
		border-radius: 0.1875rem;
	  }
  
	  // Scrollbar customization for firefox
	  scrollbar-width: thin;
	  scrollbar-color: #bac1c7 $white;
	}
  }
  
  // MARK: Native Button
  button {
	font-family: $font-family;
  }
  
  // MARK: Angular notifier
  .notifier__notification-message {  
	font-family: $font-family;
  }