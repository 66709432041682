@use "sass:map";
@import "color-palettes";

/*
modified items: please keep a list
  - cdk overlay
  - material form field
  - material select
  - material tooltip
  - material checkbox
  - material button
  - material button toggle
  - material table
  - material dialog
  - material menu
  - material icon
  - material card
  - material tab
  - material radio button
  - material expansion panel
  - material slide toggle
  - material chip
*/

// MARK: cdk overlay
//below is a setting that affects all material pop ups on a page that scrolls
//it prevents the side nav and the background color from being scrolled as well
.cdk-global-scrollblock {
  position: static;             // this keeps the page from scrolling partially out of site
  overflow: hidden !important;  // remove this if you want to show the vertical scroll bar
}

// Below is a setting that affects all material pop ups
// It ensures the header bar is behind the cdk-overlay-container
.cdk-overlay-container {
  z-index: 2000 !important;
}

// MARK: Form Field
mat-form-field {
  width: 100%;

  &.form-input {
    font-size: .875rem;
    font-family: $font-family !important;
    line-height: 1rem;
  }
}

html {
  --mdc-filled-text-field-caret-color: #2ea2f8;
  --mdc-filled-text-field-focus-active-indicator-color: #2ea2f8;
  --mdc-filled-text-field-focus-label-text-color: #2ea2f8;
  --mdc-filled-text-field-container-color: #fff;
  --mdc-filled-text-field-input-text-color: #1F1F1F; // map.get($ink-palette, 900)
  --mdc-filled-text-field-label-text-font: "Open Sans", serif;
  --mdc-filled-text-field-label-text-size: 0.875rem;
  --mat-form-field-container-text-font: "Open Sans", serif;
  --mat-form-field-subscript-text-font: "Open Sans", serif;
}

.mat-mdc-text-field-wrapper.mdc-text-field--filled {
  --mdc-filled-text-field-active-indicator-height: 0px;
  --mdc-filled-text-field-focus-active-indicator-height: 0px;
  --mdc-filled-text-field-container-shape: 4px;
  border-radius: var(--mdc-filled-text-field-container-shape);
  border: $form-input-border;

  &:not(.mdc-text-field--disabled) {
    &.mdc-text-field--focused {
      border: 1px solid $form-focus-border-color;
    }
  
    &.mdc-text-field--invalid {
      border: 1px solid map.get($error-palette, 500);
    }
  }
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-mdc-text-field-wrapper {
  .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 25px;
  }

  &:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 23px;
    padding-bottom: 9px;
    min-height: unset;
  }

  .mat-mdc-select-arrow-wrapper {
    height: 16px;
    align-items: flex-end;
  }
}

.mat-mdc-form-field-error-wrapper {
  padding: 0;
}

.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
  color: map.get($ink-palette, 700);
}

// MARK: Select
html {
  --mat-option-selected-state-label-text-color: #0073D0; // map.get($primary-palette, 500);
  --mat-option-selected-state-layer-color: #e5f5fc; 
}

html {
  --mat-select-trigger-text-font: "Open Sans", serif;
  --mat-select-trigger-text-line-height: 1rem;
  --mat-select-trigger-text-size: 0.875rem;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-focused-arrow-color: #0073D0; // map.get($primary-palette, 500);
}

.mat-mdc-select,
.mat-mdc-floating-label {
  font-family: $font-family !important;

  &.mat-select-disabled {
    --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  }
}

.mat-mdc-option .mdc-list-item__primary-text {
  overflow: hidden;
}

html {
  --mat-option-label-text-font: "Open Sans", serif;
  --mat-option-label-text-size: 0.875rem;
  --mat-option-label-text-tracking: normal;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0073D0; // map.get($primary-palette, 500);
}

// MARK: Tooltip
.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: "Open Sans", serif;
}

// MARK: Checkbox
html,
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: #D1D6DB;
  --mdc-checkbox-disabled-unselected-icon-color: #D1D6DB;
  --mdc-checkbox-selected-focus-icon-color: #3185FC;
  --mdc-checkbox-selected-hover-icon-color: #3185FC;
  --mdc-checkbox-selected-icon-color: #3185FC;
  --mdc-checkbox-selected-pressed-icon-color: #3185FC;
  --mdc-checkbox-unselected-focus-icon-color: #ACB3B9;
  --mdc-checkbox-unselected-hover-icon-color: #ACB3B9;
  --mdc-checkbox-unselected-icon-color: #D1D6DB;
  --mdc-checkbox-unselected-pressed-icon-color: #D1D6DB;
  --mdc-checkbox-selected-focus-state-layer-color: #3185FC;
  --mdc-checkbox-selected-hover-state-layer-color: #3185FC;
  --mdc-checkbox-selected-pressed-state-layer-color: #3185FC;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mdc-theme-text-primary-on-background: #1f1f1f;
  --mdc-checkbox-state-layer-size: 36px;
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);
  margin: 0;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
}

.mat-mdc-table .mat-mdc-checkbox .mdc-checkbox {
  // Set checkbox margin to equal and opposite the padding of the checkbox when in a table
  margin: 0 calc((var(--mdc-checkbox-state-layer-size) - 16px) / -2);
}

.mat-mdc-checkbox .mdc-form-field {
  font-family: $font-family;
  font-size: 14px;
  line-height: 1.375;
  font-weight: 400;
  letter-spacing: normal;
}

.mat-mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
  left: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
  width: 16px;
  height: 16px;
  border: 1px solid #D1D6DB;
  border-radius: 4px;
  background: linear-gradient(to top, #f2f4f7, #ffffff);
}

.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: $form-input-disabled-color;
}

.mat-mdc-checkbox.mat-mdc-checkbox-checked label {
  font-weight: 600;
}

.mat-mdc-checkbox {
  .mdc-checkbox__native-control {
    &:enabled:checked,
    &:enabled:indeterminate,
    &[data-indeterminate=true]:enabled {
      &~.mdc-checkbox__background {
        border-width: 2px;
        background-image: linear-gradient(to top, $form-checkbox-background-color, $form-checkbox-background-color);
        border-color: $form-checkbox-background-color !important;
      }
    }
    
    &[disabled]:checked,
    &[disabled]:indeterminate,
    &[data-indeterminate=true][disabled] {
      &~.mdc-checkbox__background {
        border-width: 2px;
        background: #0073D060 !important;
        border-color: transparent !important;
      }
    }
  }
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    background-image: linear-gradient(to top, $form-checkbox-background-color-disabled, #D1D6DB80);
  }
}

// MARK: Button
.mdc-button {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0073d0;
  --mat-mdc-button-persistent-ripple-color: #0073d0;
  --mat-mdc-button-ripple-color: #0073d01a;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0073d0;
  &:disabled {
    --mdc-filled-button-disabled-container-color: #0073D040; // map.get($primary-palette, 100)
    --mdc-filled-button-disabled-label-text-color: #ffffff;
  }
}

.mat-mdc-outlined-button {
  background-image: linear-gradient(to top, #f2f4f7, $white) !important;
  border: $form-input-border !important;
  &.mat-primary {
    color: #495057;
    --mdc-outlined-button-label-text-color: #495057;
    --mat-mdc-button-persistent-ripple-color: #0073d0;
    --mat-mdc-button-ripple-color: #0073d01a;
  }
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0073d0;
}

html {
  --mat-icon-color: #777E85; // map.get($ink-palette, 600);
}

.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0073d0;
  --mat-mdc-button-persistent-ripple-color: #0073d0;
  --mat-mdc-button-ripple-color: #0073d01a;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mdc-fab-container-color: #ffffff;
  --mat-icon-color: #1F1F1F;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0073d0;
  --mat-icon-color: #ffffff;
}

html {
  --mat-standard-button-toggle-selected-state-background-color: #0073d0;
  --mat-standard-button-toggle-selected-state-text-color: #ffffff;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #ffffff;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #0073d040;
  --mat-standard-button-toggle-divider-color: #ced4da;
  --mat-standard-button-toggle-height: 36px;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-text-font: "Open Sans", serif; // Delete after Material 17 Upgrade

  // Below are variables introduced in Material 17
  --mat-standard-button-toggle-label-text-font: "Open Sans", serif;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-weight: 600;
}

:host ::ng-deep .mat-button-toggle-appearance-standard {
  background-image: linear-gradient(to top, #f2f4f7, #FFF);

  &.mat-button-toggle-checked {
    background-image: unset;
  }
}

:host ::ng-deep .mat-button-toggle-button {
  min-width: 64px;

  font-size: 14px; // Delete after Material 17 Upgrade
  font-weight: 600; // Delete after Material 17 Upgrade
  .mat-button-toggle-label-content {
    padding: 0 16px;
  }
}

// MARK: Table
.mat-mdc-table {
  font-weight: 400;
  color: map.get($ink-palette, 900);

  .mat-mdc-header-row {
    min-height: 36px;
    padding-top: 1rem;
    border-bottom: solid 1px map.get($ink-palette, 300);
    .mat-mdc-header-cell {
      font-size: 10px;
      line-height: 1;
      color: map.get($primary-palette, 900);

      padding: 0 0.625rem 0 1rem;

      &:last-child {
        margin-left: auto;
      }
    }
  }

  .mat-mdc-row {
    min-height: 36px;
    border-bottom: none;
    .mat-mdc-cell {
      padding: 0 0.625rem 0 1rem;
    }
    &:last-child {
      margin-left: auto;
    }
    &:nth-child(even){background-color: #f1f4f8;}
    &:hover, &:focus {
      background-color: map.get($primary-palette, 50) !important;
      outline: map.get($primary-palette, 500) auto 1px;
    }
    &.selected {
      background-color: map.get($primary-palette, 50) !important;
    }
  }
}

// MARK: Dialog
.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 8px !important;
  --mdc-dialog-subhead-color: #1f1f1f;
  --mdc-dialog-supporting-text-color: #1f1f1f;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "Open Sans", serif;
  --mdc-dialog-subhead-line-height: 1.45;
  --mdc-dialog-subhead-size: 22px;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: "Open Sans", serif;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.0178571429em;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  overflow: visible;
}

.mdc-dialog__container {
  min-width: 450px !important;
  max-width: 1100px !important;
}

.mdc-dialog__title {
  padding: 0 32px 32px 32px !important;
}

.mdc-dialog__content {
  padding: 0 32px 32px 32px !important;
  max-height: 70vh;
}

.mdc-dialog__actions {
  border-top: solid 1px $border-color !important;
  padding: 16px !important;
  // justify-content: flex-end !important;
}

// MARK: Menu
html {
  --mat-menu-item-label-text-font: "Open Sans", serif;
  --mat-menu-item-label-text-size: 14px;
}

// MARK: Icon
.mat-icon {
  overflow: visible !important;

  &.disabled svg {
    fill: $mat-icon-disabled-color;
  }
}

// MARK: Card
mat-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
}

// MARK: Tab
div[role=tablist] {
  border-bottom: 1px solid #D1D6DB;
}

.mat-mdc-tab {
  min-width: 150px !important;
  height: 35px !important;
  flex-grow: 0 !important;
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0073d0;
  --mat-tab-header-inactive-label-text-color: #464B51;
  --mat-tab-header-active-label-text-color: #0073d0;
  --mat-tab-header-active-ripple-color: #0073d0;
  --mat-tab-header-inactive-ripple-color: #0073d0;
  --mat-tab-header-inactive-focus-label-text-color: #464B51;
  --mat-tab-header-inactive-hover-label-text-color: #464B51;
  --mat-tab-header-active-focus-label-text-color: #0073d0;
  --mat-tab-header-active-hover-label-text-color: #0073d0;
  --mat-tab-header-active-focus-indicator-color: #0073d0;
  --mat-tab-header-active-hover-indicator-color: #0073d0;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: "Open Sans", serif;
  --mat-tab-header-label-text-tracking: 0.0178571429em; // 0.25px
}

// MARK: Radio Button
.mat-mdc-radio-button .mdc-radio__inner-circle {
  background: linear-gradient(0deg, #f2f4f7 0%, #ffffff 100%);
}

.mat-mdc-radio-button.mat-mdc-radio-disabled.mat-mdc-radio-checked .mdc-radio__outer-circle {
  border-width: 6px;
}

.mat-mdc-radio-button:not(.mat-mdc-radio-disabled).mat-mdc-radio-checked {
  .mdc-radio__outer-circle {
    border-width: 6px;
  }

  .mdc-radio__inner-circle {
    border-style: none;
  }
}

.mat-mdc-radio-button .mdc-radio__outer-circle {
  border-width: 1px;
  background: linear-gradient(0deg, #f2f4f7 0%, #ffffff 100%);
}

.mat-mdc-radio-button .mdc-label {
  font-family: $font-family;
  font-size: 14px;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-label {
  font-weight: 600;
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #acb3b9;
  --mdc-radio-unselected-focus-icon-color: #d1d6db;
  --mdc-radio-unselected-hover-icon-color: #d1d6db;
  --mdc-radio-unselected-icon-color: #d1d6db;
  --mdc-radio-unselected-pressed-icon-color: #d1d6db;
  --mdc-radio-selected-focus-icon-color: #0073d0;
  --mdc-radio-selected-hover-icon-color: #0073d0;
  --mdc-radio-selected-icon-color: #0073d0;
  --mdc-radio-selected-pressed-icon-color: #0073d0;
  --mat-radio-checked-ripple-color: #0073d0;
}

// MARK: Expansion Panel
html {
  --mat-expansion-header-text-color: #1f1f1f;
  --mat-expansion-header-disabled-state-text-color: #49505761 !important;
  --mat-expansion-header-text-font: $font-family;
  --mat-expansion-header-text-size: 16px;
  --mat-expansion-header-text-weight: 600;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: normal;
}

html {
  --mat-expansion-header-collapsed-state-height: 52px;
  --mat-expansion-header-expanded-state-height: 60px;
}

mat-expansion-panel{
  border: 1px solid #D1D6DB;
  box-shadow: none !important;
}

//MARK: slide toggle
html,
.mdc-switch {
  --mdc-switch-disabled-selected-icon-opacity: 1 !important;
  --mdc-switch-disabled-track-opacity: 1 !important;
  --mdc-switch-disabled-unselected-icon-opacity: 1 !important;
  --mdc-switch-handle-height: 16px !important;
  --mdc-switch-handle-shape: 8px !important;
  --mdc-switch-handle-width: 16px !important;
  --mdc-switch-selected-icon-size: 18px !important;
  --mdc-switch-track-height: 20px !important;
  --mdc-switch-track-shape: 10px !important;
  --mdc-switch-track-width: 36px !important;
  --mdc-switch-unselected-icon-size: 18px !important;
  --mdc-switch-selected-focus-state-layer-opacity: 0.24 !important;
  --mdc-switch-selected-hover-state-layer-opacity: 0.12 !important;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.24 !important;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.12 !important;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.25;
  --mat-switch-disabled-unselected-handle-opacity: 1;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 16px;
  --mat-switch-pressed-handle-size: 16px;
  --mat-switch-with-icon-handle-size: 16px;
  --mat-switch-selected-handle-horizontal-margin: 0 2px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 2px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-unselected-handle-horizontal-margin: 0 2px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 2px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 0px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 0px;
  --mat-switch-disabled-unselected-track-outline-width: 0px;
  --mat-switch-disabled-unselected-track-outline-color: transparent
}

html,
.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #0080E7;
  --mdc-switch-selected-handle-color: #fff;
  --mdc-switch-selected-hover-state-layer-color: #0080E7;
  --mdc-switch-selected-pressed-state-layer-color: #0080E7;
  --mdc-switch-selected-focus-handle-color: #fff;
  --mdc-switch-selected-hover-handle-color: #fff;
  --mdc-switch-selected-pressed-handle-color: #fff;
  --mdc-switch-selected-focus-track-color: #0080E7;
  --mdc-switch-selected-hover-track-color: #0080E7;
  --mdc-switch-selected-pressed-track-color: #0080E7;
  --mdc-switch-selected-track-color: #0080E7;
  --mdc-switch-disabled-selected-handle-color: #fff;
  --mdc-switch-disabled-selected-track-color: #0080E740;
  --mdc-switch-disabled-unselected-handle-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #D1D6DB;
  --mdc-switch-unselected-focus-handle-color: #fff;
  --mdc-switch-unselected-focus-state-layer-color: #A3ADB7;
  --mdc-switch-unselected-focus-track-color: #A3ADB7;
  --mdc-switch-unselected-handle-color: #fff;
  --mdc-switch-unselected-hover-handle-color: #fff;
  --mdc-switch-unselected-hover-state-layer-color: #A3ADB7;
  --mdc-switch-unselected-hover-track-color: #A3ADB7;
  --mdc-switch-unselected-pressed-handle-color: #fff;
  --mdc-switch-unselected-pressed-state-layer-color: #A3ADB7;
  --mdc-switch-unselected-pressed-track-color: #A3ADB7;
  --mdc-switch-unselected-track-color: #A3ADB7;
}

html,
.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 40px
}

.mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: "Open Sans", serif;
  // Should be able to safely remove after Angular Material 17 upgrade
  --mat-slide-toggle-label-text-font: "Open Sans", serif;
}

// Should be able to safely remove after Angular Material 17 upgrade
.mat-mdc-slide-toggle .mdc-form-field {
  font-family: "Open Sans", serif;
}

// Should be able to safely remove after Angular Material 17 upgrade
.mdc-switch .mdc-switch__handle-track {
  width: calc(100% - 4px - var(--mdc-switch-handle-width)) !important;
  left: 2px;
}

.mat-mdc-slide-toggle .mdc-label {
  padding-left: 8px !important;
}

// Should be able to safely remove after Angular Material 17 upgrade
.mat-mdc-slide-toggle .mdc-switch:hover .mdc-switch__ripple::after {
  opacity: .12 !important;
}

// Should be able to safely remove after Angular Material 17 upgrade
.mat-mdc-slide-toggle.mat-mdc-slide-toggle-focused .mdc-switch .mdc-switch__ripple::after {
  opacity: .24 !important;
}


//CHIP
.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: "Open Sans", serif;
  --mdc-chip-label-text-color: rgba(0, 0, 0, .9);
  --mdc-chip-elevated-container-color: rgba(0, 0, 0, 0.075);
}