@use 'sass:math';
$font-family: 'Open Sans', serif;

// Colors
$app-background: #e7eaef;
$body-background: #F5F5F6;
$white: #FFF;
$black: #000;
$border-color: #C7CED9;
$header-font-color: #1F1F1F;
$title-color: #3A393A;

$success: #18A966;
$danger: #EB3530;
$warning: #f7981c;
$primary: #0073D0;
$primary-alpha: rgba(0,115,208, 0.54);
$primary-light: #E5F5FC;

$brand-blue-light: #0081b3;
$brand-blue-dark:  #002856;

$form-input-placeholder-color: #D3D3D3;
$form-input-border: 1px solid #CED4DA;
$form-input-color: #495057;
$form-input-label-color: #79797A;
$form-focus-border-color: #2EA2F8;
$form-focus-input-color: #2EA2F8;

$font-weight-normal: 300;
$font-weight-bold: 400;
$font-weight-bolder: 600;

$font-size-sm: 13px;

$layout-xl-size: 1200px;
$layout-lg-size: 992px;
$layout-md-size: 768px;
$layout-sm-size: 576px;

$container-width: 904px;

$break-small: 768px;


$form-field-disabled-background-color: #FAFAFA;
$input-disabled-color: rgba(0,0,0,.38);

$mat-icon-disabled-color: #ACB3B9;
$mat-trash-color: #EB3530;

$form-input-disabled-color: #49505761;
$form-checkbox-background-color: #3185FC;
$form-checkbox-background-color-disabled: #D1D6DB;

$table-font-color: #323A45;
$table-even-color: #F1F4F8;

$box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
$border-box: 1px solid $border-color;

$gradient-color: #f2f4f7;
$background-image-stroked-button: linear-gradient(to top, #f2f4f7, white);